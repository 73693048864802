<template>
    <div id="app" :class="{ 'l-container': $env === 'development' }">
        <contact-form fromComponent="contact"></contact-form>
    </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import { setup } from "@/mixins/setup";

export default {
    mixins: [setup],
    components: {
        ContactForm,
    },
};
</script>

<style></style>
