import Vue from "vue";
import Contact from "./Contact.vue";
import store from "../../store";
import setup from "../../utils/setup";

setup(Vue);

new Vue({
    store,
    render: h => h(Contact)
}).$mount("#app");
